import React from 'react';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
} from 'reactstrap';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import ScrollToTop from 'react-scroll-up';
import Layout from '../../components/Layout';
import PageHeading from '../../components/PageHeading/PageHeading';
import styles from '../../css/officialSongs.module.css';
import { normalizeVietnamese } from '../../util/utils';
import parameterize from 'parameterize';

const Prayers = ({ data }) => {
  const {
    allPrayer: { nodes: prayers },
  } = data;

  return (
    <Layout>
      <Helmet
        title="VEYM | Prayers"
        meta={[
          {
            name: 'description',
            content: `Prayers (Kinh Nguyện) - Common prayers used in TNTT`,
          },
        ]}
      />
      <PageHeading>Prayers</PageHeading>
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/resources">Resources</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Prayers</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <Container>
        <br />
        <br />
        <div className={styles.songs}>
          <h3>Prayers</h3>
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <ListGroup>
                {prayers
                  .sort((a, b) => (a.sort < b.sort ? -1 : 1))
                  .map(prayer => (
                    <ListGroupItem
                      tag="a"
                      href={`#${parameterize(
                        normalizeVietnamese(prayer.name ?? prayer.name_viet)
                      )}`}
                      key={prayer.id}
                    >
                      {`${prayer.name_viet ?? prayer.name}${
                        prayer.name_viet && prayer.name
                          ? ` | ${prayer.name}`
                          : ''
                      }`}
                    </ListGroupItem>
                  ))}
              </ListGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={12} md={12}>
              {prayers.map(prayer => (
                <div key={prayer.id}>
                  <Row>
                    <Col sm={12} md={6}>
                      <h5
                        id={parameterize(
                          normalizeVietnamese(prayer.name ?? prayer.name_viet)
                        )}
                      >{`${prayer.name_viet ?? prayer.name}`}</h5>
                    </Col>
                    <Col sm={12} md={6}>
                      <h5>
                        {prayer.name_viet && prayer.name ? prayer.name : null}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    {prayer.content_viet && (
                      <Col sm={12} md={6}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: prayer.content_viet,
                          }}
                        />
                      </Col>
                    )}
                    <Col sm={12} md={6}>
                      <div
                        dangerouslySetInnerHTML={{ __html: prayer.content }}
                      />
                    </Col>
                  </Row>
                  <hr />
                </div>
              ))}
              <br />
            </Col>
          </Row>
        </div>
        <ScrollToTop showUnder={160} duration={1050}>
          <Button size="sm" className={styles.button}>
            <i className="fa fa-arrow-circle-up" />
          </Button>
        </ScrollToTop>
      </Container>
      <br />
      <br />
      <br />
    </Layout>
  );
};

export default Prayers;

export const frontmatter = {
  title: 'Prayers',
  path: '/resources/prayers',
  category: 'resources',
  description: 'Prayers and other songs',
  tags:
    'resource, prayers, other songs, kinh dang minh huynh truong, kinh dang ngay, kinh ruoc le thieng lieng, tin kinh, an nan toi, truyen tin, nu vuong, sang soi, truyen giao, angelus, rosary, mysteries, nam su vui sang thuong mung',
};

export const pageQuery = graphql`
  query MyQuery {
    allPrayer {
      nodes {
        name
        name_viet
        content
        content_viet
        sort
        id
      }
    }
  }
`;
